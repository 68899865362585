import React from 'react';

function Header() {
  return (
    <div id="logo-container">
      <img src="logo.png" alt="LOGO" width="260" height="220" />
    </div>
  );
}

export default Header;

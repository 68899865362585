import React from 'react';

const SearchBar = ({ onSearch }) => {
    const handleSearch = () => {
        const keyword = document.getElementById('search-input').value.toLowerCase();
        onSearch(keyword);
    };

    return (
        <div className="container my-4">
            <div className="input-group">
                <input type="text" id="search-input" className="form-control" placeholder="Search by keyword" />
                <button className="btn btn-primary" onClick={handleSearch}>Search</button>
            </div>
        </div>
    );
}

export default SearchBar;

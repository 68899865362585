import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import Content from './components/Content';
import Footer from './components/Footer';

function App() {
    const [keyword, setKeyword] = useState('');

    const handleSearch = (keyword) => {
        setKeyword(keyword);
    };

    return (
        <div className="App">
            <Header />
            <SearchBar onSearch={handleSearch} />
            <Content keyword={keyword} />
            <Footer />
        </div>
    );
}

export default App;

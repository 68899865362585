import React, { useEffect, useState } from 'react';

const Content = ({ keyword }) => {
    const [content, setContent] = useState({ row1: [], row2: [] });
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQcyeAmB4XExx1s4j_6Ea-p267Q80QSW2VsyQbdfZ0oLUnX23TOWjU2Fzg9JIq4sAU-MiIFV5yeN3LR/pub?gid=950204393&single=true&output=csv')
            .then(response => response.text())
            .then(data => {
                const rows = data.split('\n').map(row => row.split(','));
                const headers = rows[0];
                const half = Math.ceil((rows.length - 1) / 2);
                const row1Data = rows.slice(1, half + 1);
                const row2Data = rows.slice(half + 1);

                setHeaders(headers);
                setContent({ row1: row1Data, row2: row2Data });
            })
            .catch(error => console.error('Error fetching the Google Sheet data:', error));
    }, []);

    const generateContent = (data) => {
        return data.filter(row => row.some(cell => cell.toLowerCase().includes(keyword)))
            .map((row, rowIndex) => (
                <div className="item" key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                        <div key={cellIndex}>
                            {headers[cellIndex] === 'Image URL' && <img src={cell} alt="Image" />}
                            {headers[cellIndex] === 'Title' && <h4>{cell}</h4>}
                            {headers[cellIndex] === 'Link' && <a href={cell} className="button" target="_blank" rel="noopener noreferrer">Visit Website</a>}
                            {headers[cellIndex] !== 'Image URL' && headers[cellIndex] !== 'Title' && headers[cellIndex] !== 'Link' && <p><strong>{headers[cellIndex]}</strong>: {cell}</p>}
                        </div>
                    ))}
                </div>
            ));
    };

    return (
        <div id="content-container">
            <div id="row1" className="content-row">{generateContent(content.row1)}</div>
            <div id="row2" className="content-row">{generateContent(content.row2)}</div>
        </div>
    );
};

export default Content;
